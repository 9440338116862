import React from 'react';
import { Route, Switch } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import Place from './Place';
import Help from './Help';
import AppBody from './components/App';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppBody>
        <Switch>
          <Route path={'/help'}>
            <Help />
          </Route>
          <Route>
            <Place />
          </Route>
        </Switch>
      </AppBody>
    </ThemeProvider>
  );
};

export default App;
