import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
// import clicksReducer from './counter'
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const reducers = {
  router: connectRouter(history),
};

export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    ...reducers,
    ...injectedReducers,
  });
}

export type RootState = ReturnType<typeof combineReducers>;
