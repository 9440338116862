export const theme = {
  canvas: '#fff',

  // primary
  primary: 'rgb(112, 76, 182)',
  primaryBackground: 'rgba(112, 76, 182, 0.1)',
  primaryBorder: 'rgb(189 170 226)',
  primaryStrong: 'rgb(112, 76, 182)',

  // default
  default: '#000',
  defaultBackground: '#fff',
  defaultBorder: '#e2e2e2',
  defaultStrong: '#616161',
};

export type Theme = typeof theme;

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}
